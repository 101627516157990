<template >
  <v-container fluid>
    <span>
      <span class="d-flex flex-row">
        <h1>Reporting</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="initialLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </span>
      <v-btn class="mx-1 my-1" v-for="(route, i) of reportingRoutes" :key="i" :to="route.path">{{route.name}}</v-btn>
    </span>
    <v-row>
      <v-col>
        <h2>Summary</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" v-if="sales.sales">
        <v-card outlined>
          <v-card-text>
            <h3>Total Sales</h3>
            <span class="text-h3">{{utils.formatCurrency(sales.sales)}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="sales.received">
        <v-card outlined>
          <v-card-text>
            <h3>Total Sales Received</h3>
            <span class="text-h3">{{utils.formatCurrency(sales.received)}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="sales.outstanding">
        <v-card outlined>
          <v-card-text>
            <h3>Total Sales Outstanding</h3>
            <span class="text-h3">{{utils.formatCurrency(sales.outstanding)}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="commissions.commission">
        <v-card outlined>
          <v-card-text>
            <h3>Total Commissions</h3>
            <span class="text-h3">{{utils.formatCurrency(commissions.commission)}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="commissions.paid">
        <v-card outlined>
          <v-card-text>
            <h3>Total Paid Commissions</h3>
            <span class="text-h3">{{utils.formatCurrency(commissions.paid)}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="commissions.outstanding">
        <v-card outlined>
          <v-card-text>
            <h3>Total Outstanding Commission</h3>
            <span class="text-h3">{{utils.formatCurrency(commissions.outstanding)}}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import reportingRoutes from "../../router/reporting";
import utils from "./../../plugins/helpers"
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {
      utils: utils,

      initialLoader: true,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      sales: {},
      commissions: {},

      reportingRoutes: [],
      excludeRoutes: ['supplierInventory']
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint'])
  },
  async mounted(){
    try { 
      this.reportingRoutes = reportingRoutes.filter(x=>x.name!=="reports" && !this.excludeRoutes.find(y => x.name.toLowerCase().includes(y.toLowerCase())) && !this.excludeRoutes.find(y => x.path.toLowerCase().includes(y.toLowerCase())));

      let sales = await axios.get(`${this.getEndpoint}/api/reporting/summary/sales`)
      if(sales.data.error) throw sales.data.error
      this.sales = sales.data.data;

      let commissions = await axios.get(`${this.getEndpoint}/api/reporting/summary/commissions`)
      if(commissions.data.error) throw commissions.data.error
      this.commissions = commissions.data.data;

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
